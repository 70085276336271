.spicee-dropzone {
    height: 200px;
    width: 200px;
    background-color: #fff;
    border: 2px dashed #888888;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px;
}

.spicee-dropzone.highlight {
    background-color: #75CDF3;
    border: 2px solid #75CDF3;
    color: #fff;
}

.spicee-dropzone-icon {
    opacity: 0.3;
    font-size: 64px;
    height: 64px;
    width: 64px;
}

.spicee-dropzone.highlight > .spicee-dropzone-icon {
    color: #fff;
}

.spicee-file-input {
    display: none;
}