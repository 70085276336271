.spicee-progress-bar {
    width: 100%;
    height: 8px;
    border: 1px solid #75CDF3;
    background-color: #ffff;
    border-radius: 5px;
}

.spicee-progress {
    background-color: #75CDF3;
    height: 100%;
    margin: 0;
    transition: width 0.5s ease;
}
