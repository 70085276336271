.row {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.col {
  flex: 1;
	padding: 15px;
}

.flex {
	display: flex;
}

.align-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.spicee-root {
  min-height: 100vh;
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.spicee-header {
  background-color: #ffffff;
  box-shadow: 0px 7.5px 15px rgba(0, 0, 0, 0.035);
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
  left: 0;
}

.spicee-nav {
  display: flex;
  justify-content: space-between;
  height: 60px;
}

.spicee-nav-item {
  align-items: center;
  height: 100%;
  padding: 10px;
}

.spicee-nav-item.header {
  font-weight: bold;
  font-size: 28px;
  display: flex;
  justify-content: center;
}

.spicee-logo {
  justify-self: left;
  padding-left: 10px;
  height: 100%;
  pointer-events: none;
}

.spicee-background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  /* margin: auto; */
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
}

.spicee-logo.background {
  /* max-width: 50%; */
  margin: 20px;
  height: 70px;
  pointer-events: none;
  opacity: 0.2;
}

.spin-logo {
  animation: pulse infinite 1.5s ease-in-out;
}

.spin-export {
  animation: pulse infinite 1.5s ease-in-out;
}

.spicee-background span {
  text-align: center;
}

.spicee-nav-item.upload {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  margin-right: 10px;
}


.spicee-container {
  /* header padding */
  padding-top: 60px;
  /* footer padding */
  padding-bottom: 60px;
  min-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.spicee-body {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.spicee-no-results {
  justify-self: center;
  align-self: center;
}

.spicee-search-card {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 20px;
  background-color: #fff;
  max-width: 90%;
  width: 90%;
}

.spicee-result-text {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.spicee-reactive-list {
  text-align: left;
}

.spicee-reactive-list > div > a {
  border: none;
  animation: fadein 0.5s ease-out;
}

.spicee-results-card {
  padding: 50px;
  box-shadow: 0px 7.5px 10px rgba(0, 0, 0, 0.035);
  border-radius: 5px;
  width: 100%;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.spicee-results-card.expanded {
  border-left: 6px solid #75CDF3;
}

.spicee-results-card:hover {
  box-shadow: 0px 7.5px 10px rgba(0, 0, 0, 0.19);
}

.spicee-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0px -7.5px 15px rgba(0, 0, 0, 0.035);
  z-index: 1;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.spicee-footer-nav {
  width: 100%;
  max-height: 0px;
  padding-left: 20px;
  padding-right: 20px;
  transition-property: all;
	transition-duration: .5s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.spicee-footer-nav.show {
  display: flex;
  justify-content: space-between;
  max-height: 60px;
}

.spicee-footer-nav-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.spicee-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background: #75CDF3;
  color: #fff;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 7.5px 15px rgba(0, 0, 0, 0.035);
  cursor: pointer;
  margin-top: 2px;
  margin-bottom: 2px;
  padding: 5px 10px;
  transition: background-color 0.3s ease;
}

.spicee-button.clear {
  color: #fff;
  background: #888888;
  font-weight: bold;
}

.spicee-button.upload {
  background: #75CDF3;
}

.spicee-button.done {
  background: #37FFB7;
  color: #424242;
}

.spicee-button.disabled {
  cursor: not-allowed;
  pointer-events: none;
  background: #ccc;
}

.spicee-button:focus {
  outline: none;
  background: #06A6D8;
}

.spicee-button.clear:focus {
  outline: none;
  background: #424242;
}

.spicee-button.upload:focus {
  outline: none;
  background: #06A6D8;
}

.spicee-button.done:focus {
  outline: none;
  background: #37FFB7;
}

.spicee-button:hover {
  outline: none;
  background: #06A6D8;
}

.spicee-button.clear:hover {
  outline: none;
  background: #424242;
}

.spicee-button.upload:hover {
  outline: none;
  background: #06A6D8;
}

.spicee-button.done:hover {
  outline: none;
  background: #37FFB7;
}

.spicee-pagination > a:hover {
  color: #fff;
  background-color: #75CDF3;
}

.spicee-dropdown {
  animation: fadein 0.5s ease-out;
}

.spicee-dropdown button {
  box-shadow: 0px 7.5px 15px rgba(0, 0, 0, 0.035);
  border: 2px solid #F2F2F2;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
}

.spicee-dropdown ul {
  top: 72px;
  font-size: 1.1rem;
}

.spicee-dropdown button > div {
  font-size: 1.1rem;
}

.spicee-dropdown button > span {
  color: #75CDF3;
  font-size: 2.5rem;
  line-height: 3rem;
}

.spicee-details-container {
  transition: all 0.25s ease;
  border: 2px solid #888888;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.spicee-details-container:hover {
  border-color: #888888;
  background-color: #888888;
  color: #fff;
}

.spicee-details-container > p {
  padding: 15px;
}

.spicee-details {
  overflow: hidden;
  padding-top: 10px;
}

.spicee-details > .highlight {
  background-color: rgba(117, 205, 243, 0.19);
  border-radius: 5px;
  color: #666666;
}

mark {
  background-color: rgba(255, 58, 212, 0.8);
  color: white;
}

hr {
  /* color: #EAF9FF; */
  width: 90%;
  border: 1px solid #F2F2F2;
}

.spicee-link {
  color: #4F91AE;
}

input[type='checkbox'].check-custom {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

input[type='checkbox'].check-custom ~ .check-toggle {
  width: 35px;
  height: 35px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border: 2px solid #999999;
  border-radius: 5px;
  cursor: pointer;
}

input[type='checkbox'].check-custom.highlight ~ .check-toggle {
  border: 2px solid #999999;
}

/* Styles for the hover state of the custom checkbox */
input[type='checkbox'].check-custom:hover ~ .check-toggle {
  border-color: #666666;
}

/* Styles for the focus state of the custom checkbox */
input[type='checkbox'].check-custom:focus ~ .check-toggle {
  border-color: #666666;
}

input[type='checkbox'].check-custom.highlight:hover ~ .check-toggle,
input[type='checkbox'].check-custom.highlight:focus ~ .check-toggle
{
  border-color: #666666;
}

/* Styles for the checked state of the custom checkbox */
input[type='checkbox'].check-custom:checked ~ .check-toggle,
input[type='checkbox'].check-custom.highlight:checked ~ .check-toggle
{
  border-color: #75CDF3;
  background: #75CDF3 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmZmZmYiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIj48cG9seWxpbmUgcG9pbnRzPSIyMCA2IDkgMTcgNCAxMiI+PC9wb2x5bGluZT48L3N2Zz4=) center no-repeat;
  background-size: 85%;
}

@media all and (max-width: 767px) {
  .row {
    flex-direction: column;
  }

  .row > .col:first-child {
    border-right: none;
    max-width: none;
  }

  .spicee-background {
    height: initial;
    position: relative;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1340px) {
  .spicee-body, .spicee-footer-nav {
    max-width: 1260px;
  }
}

@keyframes pulse {
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
}