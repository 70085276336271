.spicee-overlay {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease;
    visibility: hidden;
}

.spicee-overlay.show {
    visibility: visible;
    opacity: 1;
}

.spicee-upload-card {
    background-color: #fff;
    z-index: 3;
    padding: 30px;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 5px;
    box-sizing: border-box;
}

.spicee-upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
}

.spicee-upload-title {
    margin-bottom: 32px;
    font-size: 22px;
    font-weight: bold;
}

.spicee-upload-subtitle {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-size: 18px;
}

.spicee-upload-subtitle.error {
    font-weight: bold;
    color: #ff3a8c;
}

.spicee-upload-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
}

.spicee-upload-files {
    margin-left: 32px;
    align-items: flex-start;
    justify-items: flex-start;
    text-align: center;
    flex: 1;
    overflow-y: auto;
}

.spicee-upload-actions {
    display: flex;
    /* flex: 1; */
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
    margin-top: 32px;
}

.spicee-upload-row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
}

.spicee-check-icon {
    opacity: 1;
    margin-left: 20px;
    color: #37FFB7;
}

.spicee-progress-wrapper {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}
